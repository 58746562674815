import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="main-404 d-flex align-items-center justify-content-center">
      <div>
        <div className="main-404-wrapper">
          <h1>404</h1>
          <h3>the page you seek does not exist</h3>
        </div>
        <div className="main-404-footer">
          <p>© Copyright 2019. Curbside. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
